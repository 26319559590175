import { APIWallet } from '@betablocks/shared/lib/api/wallets'
import React from 'react'

export const BetaBlocksWalletContext = React.createContext<{
  betablocksWallets: APIWallet[]
  activeWallet: APIWallet
  setActiveWallet: (wallet) => void
}>({
  betablocksWallets: null,
  activeWallet: null,
  setActiveWallet: null,
})

type Props = React.PropsWithChildren<{
  betablocksWallets: APIWallet[]
  activeWallet: APIWallet
  setActiveWallet: (wallet) => void
}>

const BetaBlocksWalletProvider: React.FC<Props> = ({
  children,
  betablocksWallets,
  activeWallet,
  setActiveWallet,
}) => {
  return (
    <BetaBlocksWalletContext.Provider value={{ betablocksWallets, activeWallet, setActiveWallet }}>
      {children}
    </BetaBlocksWalletContext.Provider>
  )
}

export default BetaBlocksWalletProvider
