import { useRef } from 'react'
import useStorefront from './useStorefront'

const useChainIds = (): number[] => {
  const defaultChainIds = useRef([1, 137, 3, 5, 80001, 1337])
  const storefront = useStorefront()

  const _blockchains = useRef(
    storefront?.blockchains.map(
      (storefrontBlockchains) => storefrontBlockchains.blockchainContract.blockchain.chainId
    ) ?? defaultChainIds.current
  )

  return _blockchains.current
}

export default useChainIds
