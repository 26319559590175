import { APIStorefront } from '@betablocks/shared/lib/api/storefronts'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { useMemo, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UseWalletProvider } from 'use-wallet'
import '../../styles/globals.css'
import BetaBlocksWalletProvider from '../components/Widgets/BetaBlocksWalletProvider'
import NavDataProvider from '../components/Widgets/NavDataProvider'
import StorefrontProvider from '../components/Widgets/StorefrontProvider'
import UserProvider from '../components/Widgets/UserProvider'
import useChainIds from '../hooks/useChainIds'

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const chainIds = useChainIds()

  const rpcConfig = useMemo(() => {
    const blockchains =
      (pageProps.storefront as APIStorefront)?.blockchains.map(
        (storefrontBlockchains) => storefrontBlockchains.blockchainContract.blockchain
      ) ?? []

    const config = {}
    blockchains.forEach((blockchain) => {
      config[blockchain.chainId] = blockchain.rpcUrl
    })

    return config
  }, [pageProps.storefront])
  const [activeWallet, setActiveWallet] = useState(null)
  const betablocksWallets = pageProps.me?.wallets.filter((wallet) => wallet.isTatumWallet) || []

  return (
    <>
      <UserProvider user={pageProps.me}>
        <BetaBlocksWalletProvider
          betablocksWallets={betablocksWallets}
          activeWallet={activeWallet}
          setActiveWallet={setActiveWallet}
        >
          <NavDataProvider navData={pageProps.navData}>
            <StorefrontProvider storefront={pageProps.storefront}>
              <UseWalletProvider
                connectors={{
                  injected: { chainIds },
                  walletconnect: {
                    rpc: rpcConfig,
                  },
                }}
              >
                <ToastContainer hideProgressBar position="top-center" bodyClassName="break-words" />
                <NextNProgress color={pageProps.navData?.theme.themeData.colors.secondary} />
                <Component {...pageProps} />
              </UseWalletProvider>
            </StorefrontProvider>
          </NavDataProvider>
        </BetaBlocksWalletProvider>
      </UserProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
