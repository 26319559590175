import { APINavData } from '@betablocks/shared/lib/api/storefronts'
import React from 'react'

export const NavDataContext = React.createContext<{ navData: APINavData | null }>({
  navData: null,
})

type Props = React.PropsWithChildren<{
  navData: APINavData
}>

const NavDataProvider: React.FC<Props> = ({ children, navData }) => {
  return <NavDataContext.Provider value={{ navData }}>{children}</NavDataContext.Provider>
}

export default NavDataProvider
